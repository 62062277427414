<template>
  <div>
    <b-card>
        <validation-observer ref="form" slim>
          <h5>Personal Data</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="employee number"
                rules="required|min:6|max:10"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Employee Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    v-model="form.employeeNo"
                    disabled
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="full name"
                rules="required|min:1|max:255"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Fullname *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your full name"
                    v-model="form.fullName"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="nick name"
                rules="required|max:100"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Nickname *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your nick name"
                    v-model="form.nickName"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="identity card"
                rules="required|min:16|max:16"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Identity Card *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your identity card number"
                    v-model="form.identityCard"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="gender"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Gender *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.gender"
                    label="text"
                    :clearable="false"
                    :options="genders"
                    placeholder="Select gender"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="birth date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Birth Date *"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select birth date"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.birthDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.birthDate"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                  name="birth place"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Birth Place *"
                    :invalid-feedback="errors[0]"
                  >
                    <b-form-input
                      placeholder="Enter your birth place"
                      v-model="form.birthPlace"
                      trim
                      :class="{ 'is-invalid': errors.length }"
                    />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="religion"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Religion *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.religion"
                    label="name"
                    :options="religions"
                    :clearable="false"
                    placeholder="Select religion"
                    :reduce="({ id }) => id"
                    :class="{ 'is-invalid': errors.length }"/>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="NPWP"
                rules="max:21"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="NPWP"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your npwp number"
                    v-model="form.npwp"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="vaccine number"
                rules="min:21|max:21"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Vaccine Number"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your vaccine number"
                    v-model="form.vaccineNumber"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Employee Data</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="join date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Join Date *"
                  :invalid-feedback="errors[0]"
                >
                  <date-picker
                    type="date"
                    valueType="YYYY-MM-DD"
                    :value="formatDate(form.joinDate)"
                    format="DD-MM-YYYY"
                    :editable="false"
                    :clearable="false"
                    v-model="form.joinDate"
                    :class="{ 'is-invalid': errors.length }">
                  </date-picker>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="resign date"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Resign Date"
                    :invalid-feedback="errors[0]"
                  >
                    <date-picker
                      placeholder="Select resign date"
                      type="date"
                      valueType="YYYY-MM-DD"
                      :value="formatDate(form.resignDate)"
                      format="DD-MM-YYYY"
                      :editable="false"
                      :clearable="false"
                      v-model="form.resignDate"
                      :class="{ 'is-invalid': errors.length }">
                    </date-picker>
                  </b-form-group>
              </validation-provider>
              <validation-provider
                  name="position"
                  rules="required"
                  v-slot="{ errors }"
                >
                <b-form-group
                  label="Position *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.position"
                  label="text"
                  :options= "positions"
                  :clearable="false"
                  placeholder="Select your position"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                  name="role"
                  rules="required"
                  v-slot="{ errors }"
                >
                <b-form-group
                  label="Role"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.role"
                    label="role"
                    :options="employeeRoles"
                    :clearable="false"
                    placeholder="Select your role"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="objective"
                rules="min:1|max:200"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Objective *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your objective"
                    v-model="form.objective"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="profile"
                rules="min:1|max:200"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Profile *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your profile"
                    v-model="form.profile"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="bank account number"
                rules="required|numeric|max:16"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Bank Account Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your bank account number"
                    v-model="form.bankAccNumber"
                    type="text"
                    @input="restrictInput($event)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="bank name"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Bank Name *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                  v-model="form.bank"
                  label="name"
                  :options= "bankNames"
                  :clearable="false"
                  placeholder="Select your bank name"
                  :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="current salary"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Current Salary *"
                  description="Please input the nominal of current salary without punctuation. Example: 10000000"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    type="text"
                    placeholder="Enter your current salary"
                    v-model="form.currentSalary"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Address</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="address"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Address *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-textarea
                    placeholder="Enter your address"
                    v-model="form.address"
                    trim
                    rows="5"
                    :class="{ 'is-invalid': errors.length }"
                  ></b-form-textarea>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="province"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Province *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.province"
                    label="name"
                    :options="provinces"
                    :clearable="false"
                    placeholder="Select province"
                    @input="fetchCity(form.province, true)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="city"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="City *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.city"
                    label="name"
                    :options="cities"
                    :clearable="false"
                    placeholder="Select city"
                    @input="fetchDistrict(form.city, true)"
                    :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="district"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="District *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.district"
                    label="name"
                    :options="districts"
                    :clearable="false"
                    placeholder="Select district"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="postal code"
                rules="required|numeric|min:5|max:5"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Postal Code *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your postal code"
                    v-model="form.postalCode"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <h5>Knowledge</h5>
          <div class="row mt-2">
            <div class="col-lg-4">
              <validation-provider
                name="language"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Language *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.language"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="languages"
                    placeholder="Select language"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="programming language"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Programming Language"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.programmingLanguage"
                    label="name"
                    :options="programmingLanguages"
                    multiple
                    :clearable="false"
                    placeholder="Select programming language"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="operating system"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Operating System *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.operatingSystem"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="operatingSystems"
                    placeholder="Select operating system"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="framework"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Framework"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.framework"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="frameworks"
                    placeholder="Select framework"
                    :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="database"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Database"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.database"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="databases"
                    placeholder="Select database"
                    :class="{ 'is-invalid': errors.length }"
                />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="platform"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Platform"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.platform"
                    label="name"
                    multiple
                    :clearable="false"
                    :options="platforms"
                    placeholder="Select platform"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-4">
              <validation-provider
                name="development tools"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Development Tools"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.knowledge.developmentTools"
                    label="name"
                    :options="devTools"
                    multiple
                    :clearable="false"
                    placeholder="Select development tool"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="others"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Others"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter others"
                    v-model="form.other"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-4">
              <h5>Contact</h5>
              <validation-provider
                name="email address"
                rules="required|email|max:255"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Email Address *"
                  style="margin-top: 15px"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your email address"
                    v-model="form.email"
                    ttpe="email"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="phone number"
                rules="required|numeric|min:10|max:13"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Phone Number *"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your phone number"
                    v-model="form.phoneNumber"
                    type="text"
                    @input="restrictInput($event, 'phoneNumber')"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Emergency Contact Name"
                rules="required|max:25"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Emergency Contact Name"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your emergency contact name"
                    v-model="form.emergencyContactName"
                    trim
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Emergency Contact Number"
                rules="required|numeric|min:10|max:13"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Emergency Contact Number*"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    placeholder="Enter your emergency contact number"
                    v-model="form.emergencyContactNumber"
                    type="text"
                    @input="restrictInput($event, 'emergencyContactNumber')"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </div>
            <div class="col-lg-8">
              <div class="d-flex">
                <h5 class="mr-auto">Social Media</h5>
                <b-button
                  class="next merge justify-content-end ml-90"
                  @click="addFieldSocialMedia"
                  variant="primary"
                >
                  Add Social Media
                </b-button>
              </div>

              <div class="row">
                <div class="col-lg-6" v-if="form.socialMedia.length">
                  <div
                    v-for="(socialMedia, index) in form.socialMedia" :key="index"
                  >
                  <validation-provider
                    name="social media"
                    rules="max:100"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      :label="`Username Social Media ${index + 1}`"
                      description="ex: Username LinkendIn, Github, etc."
                      :invalid-feedback="errors[0]"
                    >
                      <b-form-input
                        placeholder="Enter username of your social media"
                        v-model="form.socialMedia[index].username"
                        trim
                        :class="{ 'is-invalid': errors.length }"
                      />
                    </b-form-group>
                  </validation-provider>
                  </div>
                </div>
                <div class="col-lg-6" v-if="form.socialMedia.length">
                  <div
                    v-for="(socialMedia, index) in form.socialMedia" :key="index"
                    class="d-flex justify-content-around"
                  >
                    <div class="row">
                      <div class="col">
                        <validation-provider
                          name="link social media"
                          rules="max:255"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            :label="`Link Social Media ${index + 1}`"
                            description="ex: https://www.linkedin.com/in/user-7212603b"
                            :invalid-feedback="errors[0]"
                          >
                            <b-form-input
                              placeholder="Enter link of your social media"
                              v-model="form.socialMedia[index].link"
                              trim
                              :class="{ 'is-invalid': errors.length }"
                            />
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <div class="col col-sm-3 col-lg-2 col-md-2">
                        <button
                          class="btn btn-danger"
                          style="height: 40px; margin-top: 27px"
                          @click="removeFieldSocialMedia(index)"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </validation-observer>
        <div class="d-flex justify-content-end">
          <b-button
            class="next merge d-flex justify-content-end"
            type="submit"
            variant="primary"
            @click="saveChange">
            Save Change
          </b-button>
        </div>
    </b-card>
    <br>
  </div>
</template>

<script>
import api from '@/api'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  props: {
    employee: {
      type: Object,
      default: () => ({})
    }
  },
  components: { DatePicker },
  data() {
    return {
      form: {
        fullName: '',
        nickName: '',
        employeeNo: '',
        status: '',
        gender: '',
        identityCard: '',
        birthPlace: '',
        npwp: '',
        birthDate: '',
        email: '',
        religion: '',
        phoneNumber: '',
        address: '',
        province: '',
        city: '',
        district: '',
        postalCode: '',
        vaccineNumber: '',
        objective: '',
        profile: '',
        other: '',
        emergencyContactName: '',
        emergencyContactNumber: '',
        socialMedia: [],
        language: [],
        knowledge: {},
        currentSalary: '',
        joinDate: '',
        yearsWorking: '',
        resignDate: '',
        position: '',
        bankAccNumber: '',
        bank: {
          id: '',
          name: ''
        },
        role: ''
      },
      show: true,
      religions: [],
      genders: ['Perempuan', 'Laki-laki'],
      languages: [],
      provinces: [],
      cities: [],
      cityList: [],
      districts: [],
      districtList: [],
      frameworks: [],
      platforms: [],
      operatingSystems: [],
      databases: [],
      programmingLanguages: [],
      devTools: [],
      positions: ['Founder', 'Karyawan', 'Kontrak', 'Probation'],
      employeeRoles: [],
      bankNames: []
    }
  },
  mounted() {
    this.fetchBank()
    this.fetchRole()
    this.fetchProvince()
    this.fetchLanguage()
    this.fetchFramework()
    this.fetchPlatform()
    this.fetchOperatingSystem()
    this.fetchDatabase()
    this.fetchDevTools()
    this.fetchProgrammingLanguage()
    this.fetchGetEmployeeEdit()
    this.fetchReligion()
  },
  methods: {
    async fetchBank() {
      const { data } = await api.employee.bank()
      this.bankNames = data
    },
    async fetchRole() {
      const { data } = await api.employee.role()
      this.employeeRoles = data.employeeRoles
    },
    async fetchLanguage() {
      const { data } = await api.employee.language()
      this.languages = data
    },
    async fetchProvince() {
      const { data } = await api.address.province()
      this.provinces = data.data
      this.cities = []
      this.districts = []
    },
    async fetchCity(province, isChange) {
      if (isChange) {
        this.form.city = null
        this.form.district = null
      }
      const { data } = await api.address.cityById(province.id)
      this.cities = data.data
      this.districts = []
    },
    async fetchDistrict(city, isChange) {
      if (isChange) this.form.district = null
      const { data } = await api.address.districtById(city.id)
      this.districts = data.data
    },
    async fetchReligion() {
      const { data } = await api.religions.get()
      this.religions = data.data
    },
    async fetchFramework() {
      const { data } = await api.knowledge.framework()
      this.frameworks = data
    },
    async fetchPlatform() {
      const { data } = await api.knowledge.platform()
      this.platforms = data
    },
    async fetchOperatingSystem() {
      const { data } = await api.knowledge.operatingSystem()
      this.operatingSystems = data
    },
    async fetchDatabase() {
      const { data } = await api.knowledge.database()
      this.databases = data
    },
    async fetchDevTools() {
      const { data } = await api.knowledge.devTools()
      this.devTools = data
    },
    async fetchProgrammingLanguage() {
      const { data } = await api.knowledge.programmingLanguage()
      this.programmingLanguages = data
    },
    async fetchGetEmployeeEdit() {
      const { data } = await api.employee.get(this.$route.params.id)
      this.form = data.data

      await this.fetchCity(this.form.province)
      await this.fetchDistrict(this.form.city)
    },
    formatDate(date) {
      var value = date === '' ? 'DD-MM-YYYY' : moment(date).format('YYYY-MM-DD')
      return value
    },
    restrictInput(event, field) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        if (field === 'phoneNumber') {
          this.phoneNumber = this.phoneNumber.slice(0, -1)
        } else if (field === 'emergencyContactNumber') {
          this.emergencyContactNumber = this.emergencyContactNumber.slice(0, -1)
        } else {
          this.bankAccNumber = this.bankAccNumber.slice(0, -1)
        }
      }
    },
    async saveChange() {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          this.$nextTick(() => {
            this.$bvToast.toast('Cannot save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            })
          })
          return
        }

        const newForm = {
          ...this.form,
          provinceId: this.form.province.id,
          cityId: this.form.city.id,
          districtId: this.form.district.id,
          hireDate: this.form.joinDate,
          bankId: this.form.bank.id,
          religionId: this.form.religion.id ? this.form.religion.id : this.form.religion
        }

        newForm.knowledge.employeeId = newForm.id
        newForm.status = 'active'

        delete newForm.province
        delete newForm.city
        delete newForm.district
        delete newForm.joinDate
        delete newForm.age
        delete newForm.bank
        delete newForm.religion
        delete newForm.yearsWorking

        const data = await api.employee.put(newForm, { id: this.form.id })

        if (data.data.status === 200 && newForm.id === JSON.parse(localStorage.getItem('me')).employeeId) {
          const me = JSON.parse(localStorage.getItem('me'))

          me.fullname = newForm.fullName
          me.email = newForm.email

          localStorage.setItem('me', JSON.stringify(me))
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save changes', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
          this.$router.push('/manage-employees')
        })
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    addFieldSocialMedia() {
      this.form.socialMedia.push({ username: '', link: '' })
    },
    removeFieldSocialMedia(index) {
      this.form.socialMedia.splice(index, 1)
    }
  }
}
</script>
